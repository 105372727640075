@font-face {
  font-family: 'Poppins-Bold';
  src: url('../../../fonts/Poppins-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Bold-Italic';
  src: url('../../../fonts/Poppins-BoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Semibold';
  src: url('../../../fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Semibold-Italic';
  src: url('../../../fonts/Poppins-SemiBoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../../../fonts/Poppins-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Medium-Italic';
  src: url('../../../fonts/Poppins-MediumItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../../../fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Italic';
  src: url('../../../fonts/Poppins-Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('../../../fonts/Poppins-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Light-Italic';
  src: url('../../../fonts/Poppins-LightItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Thin';
  src: url('../../../fonts/Poppins-Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// There is unused fonts yet, but have added here for any case

$font-primary-bold: 'Poppins-Bold', sans-serif; // weight: 700
$font-primary-bold-italic: 'Poppins-Bold-Italic', sans-serif; // weight: 700

$font-primary-semibold: 'Poppins-Semibold', sans-serif; // weight: 600
$font-primary-semibold-italic: 'Poppins-Semibold-Italic', sans-serif; // weight: 600

$font-primary-medium: 'Poppins-Medium', sans-serif; // weight: 500
$font-primary-medium-italic: 'Poppins-Medium-Italic', sans-serif; // weight: 500

$font-primary-regular: 'Poppins-Regular', sans-serif; // weight: 400
$font-primary-italic: 'Poppins-Italic', sans-serif; // weight: 400

$font-primary-light: 'Poppins-Light', sans-serif; // weight: 300
$font-primary-light-italic: 'Poppins-Light-italic', sans-serif; // weight: 300

$font-primary-thin: 'Poppins-Thin', sans-serif; // weight: 100


