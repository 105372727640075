@mixin mixBreakPointXS {
  @media only screen and (max-width: 320px) {
    @content;
  }
}

@mixin mixBreakPointSM {
  @media only screen
  and (max-width: 600px) {
    @content;
  }
}

@mixin mixBreakPointMD {
  @media only screen
  and (max-width: 860px) {
    @content;
  }
}

@mixin mixBreakPointLG {
  @media only screen
  and (max-width: 1200px) {
    @content;
  }
}

@mixin mixBreakPointL {
  @media only screen
  and (max-width: 1500px) {
    @content;
  }
}

@mixin mixBreakPointXL {
  @media only screen
  and (max-width: 1919px) {
    @content;
  }
}

@mixin mixBreakPointXXL {
  @media only screen
  and (min-width: 1920px) {
    @content;
  }
}
