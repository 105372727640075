.header {
  display: flex;
  padding: 1.25rem 2.5rem;
  height: 5.75rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
  position: relative;

  @include mixBreakPointSM {
    padding: 1.5rem;
  }

  &.connected {
    //.gradient {
      z-index: 10;
    //}
  }

  .gradient {
    min-width: 100vw;
    min-height: 40rem;
    position: absolute;
    top: -18rem;
    left: 0;
    width: 100%;
  }

  .menu-dropdown {
    position: relative;
    height: 100%;

  }

  .links-block {
    display: flex;
    align-items: center;
    gap: 2.25rem;
    font-size: 0.875rem;

    a {
      font-family: $font-primary-medium;
      color: $color-text-secondary;

      .title {
        color: $color-text-secondary;

        &.app {
          color: $color-text-primary;
        }
      }
    }
  }

  .header-logo {
    height: 3.25rem;
    width: 3.25rem;
    background-image: url("../../images/Logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @include mixBreakPointSM {
      height: 3rem;
      width: 3rem;
    }

  }
}
