#nav-icon3{
  width: 1.75rem;
  height: 100%;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 1000;
}

#nav-icon3 span{
  display: block;
  position: absolute;
  height: 0.125rem;
  width: 100%;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}


#nav-icon3 span:nth-child(1) {
  top: 35%;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 50%;
}

#nav-icon3 span:nth-child(4) {
  top: 65%;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}

.menu-icon-primary {
  span{
    background: $color-background-tertiary;
  }
}

.menu-icon-secondary {
  span{
    background: $color-background-default;
  }
}
