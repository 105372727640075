.empty-page {
  @include mixFlexAlignJustify(center, center, column);
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 4rem;
  padding: 1.5rem;
  z-index: 1;

  button {
    width: 200px;
  }
}
