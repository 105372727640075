.button-with-icon {
  @include mixFlexAlignJustify(center, center);
  height: 3rem;
  gap: 0.5rem;
  padding: 0 1rem;
}

.button-without-icon {
  @include mixFlexAlignJustify(center, center);
  height: 3rem;
  min-width: 10rem;

  @include mixBreakPointSM {
    min-width: 9.5rem;
  }
}

.button-connected {
  box-shadow: 1px 1px 24px rgba(10, 12, 18, 0.08);
  font-size: 0.875rem;
  font-family: $font-primary-medium;
  letter-spacing: 0.04em;
  color: $color-text-tertiary;
  background-color: $color-background-default;
  z-index: 10;
}

.action-buttons {
  display: flex;
  gap: 0.75rem;
}

.account-dropdown {
  position: relative;

  .account-menu {
    position: absolute;
    background-color: white;
    width: 100%;
    border-radius: 0.875rem;
    margin-top: 0.5rem;
    z-index: 20;

    hr {
      border: mixItemBorder($color-background-disabled);
    }

    .balance {
      @include mixFlexAlignJustify(baseline, center);
      gap: 0.25rem;

      h3 {
        font-family: $font-primary-medium;
      }
    }

    .label {
      font-size: 0.875rem;
      font-family: $font-primary-medium;
      letter-spacing: 0.04em;
      color: $color-text-tertiary;
    }

    .copy-address {
      position: relative;
      @include mixFlexAlignJustify(center, space-between);
      padding: 1rem;

      button {
        padding: 0;
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
}
