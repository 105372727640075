.impact-info-block {
  @include mixFlexAlignJustify(center, center, column);
  gap: 0.375rem;

  h3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    font-size: 0.875rem;
    height: 0.875rem;
  }
}

.pool-detail-block {
  @include mixFlexAlignJustify(start, center, column);
}

.pool-info-block {
  @include mixFlexAlignJustify(center, space-between);
  gap: 0.25rem;

  .value-block{
    @include mixFlexAlignJustify(center, space-between);
    gap: 0.25rem;
  }
}

