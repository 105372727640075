.badge {
  @include mixFlexAlignJustify(center, center);

  position: relative;
  text-transform: uppercase;

  span {
    padding: 0.5rem 1rem;
    min-width: 4.5rem;
    text-align: center;
  }

  &.long {
    left: -1.5rem;
    position: relative;

    span{
      left: -1.625rem;
      position: relative;
    }
  }

  &.arrow {
    color: $color-text-primary;
    height: 6.625rem;

    svg {
      position: absolute;
    }

    span {
      background-color: $color-background-primary;
      border-radius: 2rem;
      z-index: 1;

      h6 {
        letter-spacing: 1px;
      }
    }
  }
}
