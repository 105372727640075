// Outlined colored button with transparent background and the given color for text and border.

@mixin mixButtonOutlined($arg) {
  color: $arg;
  border: mixItemBorder($arg);
}

// Filled by given background color button with white text color.

@mixin mixButtonFilled($bg-color, $color) {
  color: $color;
  background-color: $bg-color;
  border: mixItemBorder($bg-color);
}
//
//// Rotate icon by given angle.
//
//@mixin mixIconRotate($angle) {
//  transform: rotate($angle);
//  transform-origin: center;
//}
