// view pools =====================================================

.page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14rem;

  @include mixBreakPointXL {
    gap: 2rem;
  }

  @include mixBreakPointMD {
    gap: 2rem;
    flex-direction: column-reverse;
  }

  img {
    object-fit: cover;
  }

  video, img {
    z-index: -10;
    max-width: 40%;
    width: 100%;
    height: auto;

    @include mixBreakPointXL {
      max-width: 38%;
      min-width: 31rem;
    }

    @include mixBreakPointL {
      max-width: 31rem;
      min-width: 0;
    }

    @include mixBreakPointMD {
      max-width: 26rem;
      height: auto;
      flex-direction: column-reverse;
    }

    @include mixBreakPointSM {
      max-width: 24rem;
      align-self: center;
    }
  }

  .info-block {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mixBreakPointXL {
      max-width: 50%;
    }

    @include mixBreakPointL {
      max-width: 32rem;
    }

    @include mixBreakPointLG {
      max-width: 50%;
    }

    @include mixBreakPointMD {
      max-width: 80%;
      align-items: center;
      text-align: center;
    }

    @include mixBreakPointSM {
      max-width: 100%;
    }

    h1 {
      line-height: 5rem;
      letter-spacing: -0.01em;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      background: linear-gradient(112.26deg, #EC17D7 8.73%, #344FDA 84.89%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @include mixBreakPointLG {
        font-size: 3rem;
        line-height: 4rem;
      }

      @include mixBreakPointMD {
        font-size: 3rem;
        line-height: 4rem;
      }

      @include mixBreakPointSM {
        font-size: 2.5rem;
        line-height: 3.25rem;
      }
    }

    .description {
      font-size: 1.25rem;
      line-height: 2.25rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      margin-top: 1.5rem;

      &:not(:last-child) {
        margin-bottom: 3rem;
      }

      @include mixBreakPointMD {
        line-height: 2.25rem;
        margin-top: 2rem;
        &:not(:last-child) {
          margin-bottom: 2.5rem;
        }
      }

      @include mixBreakPointSM {
        line-height: 1.75rem;
        font-size: 1rem;
      }
    }
  }

  .action-buttons {
    display: flex;
    gap: 0.75rem;

    font-family: $font-primary-regular;
  }
}

// work flow ====================================================================================

.work-flow-card {
  @include mixFlexAlignJustify(center, center, column);
  @include mixCardBorder();

  text-align: center;
  gap: 1rem;
  padding: 1.625rem 1.5rem;
  width: 100%;
  margin: auto;

  @include mixBreakPointSM {
    padding: 1.375rem 1rem;
  }

  h4 {
    color: $color-text-secondary;
  }

  span {
    line-height: 28px;
    font-size: 15px;
  }
}

// stories ========================================================================================
.story-card {
  position: relative;
  height: 100%;
  cursor: pointer;

  .skeleton {
    background: none;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    background: linear-gradient(180deg, rgba(10, 12, 18, 0) 0%, rgba(10, 12, 18, 0.9) 100%)
  }

  .image {
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 0.5rem;
    }

  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
  }

  .story-info {
    position: absolute;
    bottom: 0.75rem;
    left: 1rem;
    right: 1rem;
    font-size: 0.875rem;
    color: $color-text-primary;

    span {
      width: 100%;
      line-height: 1.375rem;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.active {
    .story-info {
      bottom: 1rem;
      left: 2rem;
      right: 2rem;
      font-size: 1.25rem;
      color: $color-text-primary;
      max-width: 40rem;

      h4 {
        font-size: 2rem;
        line-height: 4rem;
      }

      span {
        line-height: 2.25rem;
      }
    }
  }
}

// testimonials ================================================================================

.testimonial-card {
  @include mixFlexAlignJustify(flex-start, space-between, column);
  @include mixCardBorder();

  gap: 1.5rem;
  width: 28rem;
  padding: 2rem 1.75rem;
  cursor: pointer;
  opacity: 0.9;

  perspective: 100px;
  transition: transform 1s;

  &:hover {
    transform: translateZ(20px);
  }

  h5 {
    text-transform: capitalize;
    color: $color-text-default;
    font-size: 0.875rem;
  }

  .description {
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;

    p {
      line-height: 1.5rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .testimonial-info {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    font-size: 0.875rem;

    .image {
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      overflow: hidden;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    .title {
      span {
        font-family: $font-primary-italic;
      }
    }

    span {
      line-height: 1.5rem;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// Team members ================================================================================

.member-card {
  @include mixFlexAlignJustify(center, center, column);
  gap: 1.75rem;
  width: 255px;
  opacity: 0.9;


  .image {
    height: 9.25rem;
    width: 9.25rem;
    border-radius: 2.25rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .member-info {
    @include mixFlexAlignJustify(center, center, column);
    gap: 1rem;
    font-size: 0.875rem;
    height: 100%;
    text-align: center;

    .position {
      color: $color-text-tertiary;
      text-transform: uppercase;
      font-size: 0.625rem;
      font-family: $font-primary-bold;
      letter-spacing: 2px;
    }

    h5 {
      text-transform: capitalize;
      height: 1.25rem;
    }

    .description {
      line-height: 1.5rem;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

/// Keyframes ===============================================================================

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-26rem * 4));
  }
}

//Join Discord ==============================================================================

.discord-card {
  @include mixFlexAlignJustify(center, center);
  @include mixCardBorder();
  width: 100%;

  background: linear-gradient(98.82deg, #3B0C57 1.01%, #3C38F2 100.11%);
  color: $color-text-primary;

  gap: 8rem;
  padding: 2.5rem 5.5rem;

  @include mixBreakPointL {
    gap: 6rem;
  }

  @include mixBreakPointMD {
    flex-direction: column;
    gap: 2.5rem;
    padding: 2.75rem 2.75rem;
  }

  @include mixBreakPointSM {
    gap: 2.5rem;
    padding: 2.75rem 2.75rem;
  }

  img {
    object-fit: cover;
    max-height: 18rem;
    max-width: 18rem;

    @include mixBreakPointMD {
      height: auto;
      flex-direction: column;
      max-width: 30rem;
    }

    @include mixBreakPointSM {
      max-height: 18rem;
      max-width: 18rem;
      width: 100%;
    }
  }

  .discord-info {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
    max-width: 38rem;

    @include mixBreakPointMD {
      align-items: center;
      text-align: center;
    }

    @include mixBreakPointSM {
      gap: 1.25rem;
    }

    button {
      margin: 0.625rem 0;

      &:hover {
        color: $color-text-secondary;
        background-color: $color-text-primary;
      }
    }

    h2 {
      @include mixBreakPointSM {
        font-size: 1.625rem;
        line-height: 2.5rem;
      }
    }

    .description {
      line-height: 1.875rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @include mixBreakPointSM {
        font-size: 15px;
        line-height: 1.625rem;
      }
    }
  }
}

// pools ======================================================================================

.progress-block {
  @include mixFlexAlignJustify(flex-start, center, column);
  height: 3rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  border: #C5C0C8 1px solid;
  white-space: nowrap;
  gap: 0.5rem;

  .progress-info {
    @include mixFlexAlignJustify(center, space-between);
    gap: 0.5rem;
    line-height: 100%;

    .value-content {
      @include mixFlexAlignJustify(center, center);
      gap: 0.125rem;
    }

    //.value {
    //  line-height: 1.125rem;
    //}
    //
    //.symbol {
    //  line-height: 1.125rem;
    //}
  }

  .label {
    @include mixFlexAlignJustify(center, center);
    height: 100%;
    font-size: 0.75rem;
    color: $color-text-default;
  }

  .max-value-progress {
    height: 5px;
    border-radius: 5px;
    background-color: #C5C0C850;
    width: 100%;
    margin-bottom: 0.125rem;

    .current-value-progress {
      background-color: #09BC06;
      height: 100%;
      border-radius: 5px;
    }
  }
}

.pool-progress-block {
  @include mixFlexAlignJustify(center, space-between, column);
  gap: 0.5rem;
  width: 100%;
  border-radius: 0.5rem;
  white-space: nowrap;
  min-width: 10rem;

  .progress-info {
    @include mixFlexAlignJustify(center, space-between);
    width: 100%;
    gap: 0.375rem;
  }

  .current {
    font-family: $font-primary-semibold;
    color: #4F3EFF;
  }

  .total {
    font-family: $font-primary-medium;
    color: #888FCC;
  }

  .max-value-progress {
    height: 6px;
    border-radius: 6px;
    background-color: rgba(79, 62, 255, 0.16);
    width: 100%;

    .current-value-progress {
      background-color: #4F3EFF;
      height: 100%;
      border-radius: 6px;
    }
  }
}

.pool-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;

  @include mixBreakPointL {
    gap: 2rem;
  }

  @include mixBreakPointLG {
    width: 100%;
    display: grid;
    grid-auto-columns: max-content;
    grid-template-columns: repeat( auto-fit, minmax(3rem, 7rem) );
    justify-content: start;
    row-gap: 2rem;
    column-gap: 4.5rem;
  }

  @include mixBreakPointSM {
    grid-template-columns: repeat( auto-fit, minmax(1rem, 6.5rem) );
    justify-content: space-between;
    column-gap: 1rem;
    width: 17rem;
  }

  &.connected {
    gap: 2.5rem;
  }

  white-space: nowrap;

  .label {
    font-family: $font-primary-light;
    color: $color-text-default;
  }
}

.pool-card {
  @include mixCardBorder();

  height: 270px;
  width: 100%;
  display: flex;

  @include mixBreakPointLG {
    height: 100%;
    flex-direction: column;
  }

  .pool-detail-block {
    gap: 0.25rem;

    .label {
      font-size: 0.75rem;
      font-family: $font-primary-light;
      color: $color-text-tertiary;
    }

    .value-content {
      @include mixFlexAlignJustify(center, start);
      gap: 0.125rem;
    }

    .value {
      font-size: 1.125rem;
      line-height: 1.125rem;
      font-family: $font-primary-regular;
    }

    .symbol {
      font-size: 1.125rem;
      line-height: 1.125rem;
      font-family: $font-primary-regular;
    }
  }

  .closed-badge {
    @include mixFlexAlignJustify(center, center);

    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    height: 1.5rem;
    width: 5.125rem;
    text-align: center;
    color: $color-text-primary;
    background-color: $color-background-primary;
    font-family: $font-primary-medium;
    font-size: 0.75rem;
    border-radius: 3rem;
  }

  .pool-card-image {
    width: 35%;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(150deg, $color-background-toned 0%, $color-background-disabled 100%);

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    @include mixBreakPointLG {
      width: 100%;
      height: 15rem;
    }
  }

  .pool-info {
    @include mixFlexAlignJustify(flex-start, space-between, column);

    padding: 1.875rem 2.5rem;
    width: 65%;

    @include mixBreakPointLG {
      width: 100%;
      padding: 1.5rem 2rem;
      gap: 2rem;
      height: calc(100% - 15rem);
    }

    @include mixBreakPointMD {
      width: 100%;
      padding: 1.5rem;
      gap: 2rem;
    }

    @include mixBreakPointSM {
      padding: 1.25rem;
    }

    .title {
      display: flex;
      flex-direction: column;
      gap: 0.375rem;
    }
  }

  .view-projects {
    width: 100%;
    @include mixFlexAlignJustify(center, end);
    gap: 0.25rem;

    text-align: end;
    font-size: 0.875rem;

    @include mixBreakPointLG {
      justify-content: space-between;
    }

    a {
      color: $color-text-secondary;
    }

    svg {
      margin-left: 0.5rem;
    }
  }
}

// projects ==================================================================================

.project-card {
  @include mixCardBorder();

  display: flex;
  flex-direction: column;

  .project-image {
    height: 12.5rem;
    position: relative;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 1;
    }
  }

  .project-info {
    @include mixFlexAlignJustify(flex-start, space-between, column);

    gap: 1.25rem;
    padding: 1.25rem;

    .description {
      line-height: 1.5rem;
    }

    .pool-name {
      text-transform: uppercase;
      color: $color-text-tertiary;
      padding: 0.5rem 0.75rem;
      border-radius: 2.5rem;
      background-color: #F1EFF2;
      letter-spacing: 0.08em;

      h6 {
        font-family: $font-primary-medium;
        height: 0.75rem;
      }
    }
  }
}

// transactions history ========================================================================

.transaction-card {
  @include mixFlexAlignJustify(center, space-between);
  gap: 0.75rem;
  padding: 0.75rem 1.25rem;
  border-radius: 0.625rem;
  width: 100%;
  background-color: $color-background-default;
  text-align: center;

  .from {
    @include mixFlexAlignJustify(center, center);
    position: relative;

    .logo {
      height: 1.75rem;
      width: 1.75rem;
      margin-right: 0.75rem;
    }

    .copy {
      margin-left: 0.375rem;
      width: 1.25rem;
      height: 1.25rem;

      &:hover {
        cursor: pointer;
      }
    }

    .copy-popup {
      top: -1.5rem;
      right: 0;
    }
  }

  .value {
    font-family: $font-primary-medium;
  }

  .hash {
    a {
      color: $color-text-secondary;
      font-family: $font-primary-medium;
      @include mixFlexAlignJustify(center, center);
      gap: 0.25rem;

      span {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// investments =================================================================================

.investment-card {
  @include mixFlexAlignJustify(center, center, column);
  padding: 2rem 1.5rem 1.5rem 1.5rem;
  border-radius: 0.75rem;
  background-color: rgb(255, 255, 255, 0.5);;
  border: none;
  box-shadow: $box-shadow-primary;
  min-height: 436px;
  height: 100%;

  &.not-invested {
    background: linear-gradient(148deg, rgba(236, 23, 215, 0.04) 0%, rgba(52, 79, 218, 0.04) 100%), #FFF;
    box-shadow: 0 4px 24px -1px rgba(107, 8, 167, 0.24);
    padding: 2rem 1.75rem 1.75rem 1.625rem;

    .action-buttons {
      height: 3rem;
    }
  }

  h4 {
    line-height: 1.25rem;
    margin-top: 0.875rem;
  }

  .apy {
    line-height: 1.125rem;
    margin-top: 0.5rem;
  }

  .progress-block {
    margin-top: 1rem;
  }

  img, .skeleton {
    height: 3.75rem;
    width: 3.75rem;
    border-radius: 50%;
    object-fit: cover;
  }

  .account-investment-info {
    @include mixFlexAlignJustify(center, space-between, column);
    width: 100%;
    flex: 1;
    margin: 3rem 0 1.25rem 0;

    .account-balance {
      @include mixFlexAlignJustify(center, center, column);
      gap: 0.75rem;

      .label {
        font-size: 0.875rem;
        line-height: 100%;
      }

      .value {
        @include mixFlexAlignJustify(center, center);
        gap: 0.125rem;
        font-size: 1.75rem;
        line-height: 1.125rem;

        .symbol {
          font-size: 1.125rem;
        }
      }

      .withdraw {
        font-size: 0.75rem;
        color: $color-text-secondary;
        text-decoration: underline;
        line-height: 100%;
      }
    }
  }

  .pool-investment-info {
    @include mixFlexAlignJustify(center, space-between, column);
    width: 100%;
    flex: 1;
    .investment-details {
      margin: 2rem 0;

      .pool-detail-block {
        align-items: center;
      }
    }
  }

  .investment-details {
    display: grid;
    max-width: 240px;
    grid-auto-columns: max-content;
    grid-template-columns: repeat( auto-fit, minmax(10px, 96px) );
    text-align: center;
    row-gap: 1.75rem;
    column-gap: 3rem;
  }


  .pool-detail-block {
    gap: 0.5rem;
    align-items: center;

    .label {
      font-size: 0.75rem;
      line-height: 100%;
      font-family: $font-primary-regular;
    }

    .value-content {
      @include mixFlexAlignJustify(center, center);
      gap: 0.125rem;
    }

    .value {
      font-size: 1.25rem;
      line-height: 1.125rem;
      font-family: $font-primary-medium;
    }

    .symbol {
      font-size: 0.875rem;
      line-height: 1.125rem;
      font-family: $font-primary-medium;
    }
  }

  .action-buttons {
    display: flex;
    gap: 0.625rem;
    width: 100%;
    height: 2.5rem;

    .button-outlined-primary {
      background-color: transparent;

      &:hover {
        background-color: $color-background-primary;
      }
    }

    button {
      flex: 1;
      font-family: $font-primary-regular;
      font-size: 13px;

      span {
        font-family: $font-primary-semibold;
        font-size: 0.825rem;
        height: 0.825rem;
      }
    }
  }
}

// Reinvest pool card =====================================================================================

.reinvest-pool-card {
  border: 1px $color-border-secondary solid;
  border-radius: 0.5rem;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &:active {
    border: 1px $color-border-secondary solid;
  }

  &.active {
    background-color: #F7F7FF;
    border: 1px #4F3EFF solid;
  }

  @include mixFlexAlignJustify(center, space-between);
  padding: 0.75rem 1rem;
  overflow: hidden;

  .pool-name {
    @include mixFlexAlignJustify(center, center);
    gap: 1rem;
    max-width: 16rem;

    h4 {
      font-size: 1.125rem;
    }

    img {
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .label {
    font-size: 0.75rem;
    font-family: $font-primary-regular;
  }

  .pool-detail-block {
    gap: 0.5rem;

    .value-content {
      @include mixFlexAlignJustify(center, center);
      gap: 0.125rem;
    }

    .value {
      line-height: 1.125rem;
    }

    .symbol {
      line-height: 1.125rem;
    }
  }

  .info {
    height: 3rem;
  }

  .reinvest-pool-progress {
    border: none;
    line-height: 1rem;
    gap: 1rem;
    padding: 0;
  }
}


