// === GENERAL ===
$font-size-default: 16px;
$border-radius-default: 2.25rem; // 36px

// === BUTTONS ===
$button-font-size-primary: 1.375em;
$button-border-radius-primary: 6.625em;

$box-shadow-primary: 2px 2px 24px 1px rgba(0, 0, 0, 0.08);
$blur-primary: blur(3px);


$height-small: 2rem; // 32px
$height-medium: 2.5rem; // 40px
$height-large: 3rem; // 48px


$width-small: 8.5rem; // 136px
$width-medium: 9.25rem; // 148px
$width-large: 10rem; // 160px
