// TYPES

// outlined - 3 sets of styles for outlined buttons

.button-outlined-primary {
  @include mixButtonOutlined($color-border-primary);
  background-color: $color-background-default;


  &:hover {
    color: $color-text-primary;
    background-color: $color-background-primary;
  }
}

.button-outlined-secondary {
  @include mixButtonOutlined($color-text-secondary)
}

.button-outlined-default {
  @include mixButtonOutlined($color-text-default);
}

// filled - 3 sets of styles for filled buttons

.button-filled-primary {
  @include mixButtonFilled($color-background-primary, $color-text-primary);

  &:hover {
    background-color: $color-background-hover-primary;
  }
}

// disabled filled buttons

.button-disabled-primary, .button-disabled-secondary {
  @include mixButtonFilled($color-background-disabled, $color-text-disabled);
  pointer-events: none;
}
