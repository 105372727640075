// base colors
$color-pink-strong: #3925BB;
$color-pink-light: #4F3EFF;
$color-white-strong: #fff;
$color-green-strong: #09BC06;
$color-green-light: #09BC06;
$color-red-light: #E30000;
$color-orange-strong: #f19f18;
$color-pink-medium: #DC5A5A;
$color-grey-strong: #7D7A7D;
$color-grey-light: #CFCBD1; //new
$color-grey-medium: #DEDAE0;
$color-grey: #F8F5F9;
//$color-grey: #C8C8C8;
$color-black-strong: #1B0F30;
$color-black-light: #3C4868;

// text colors
$color-text-primary: $color-white-strong;
$color-text-secondary: $color-pink-light;
$color-text-tertiary: $color-black-light;
$color-text-default: $color-black-strong;
$color-text-disabled: $color-grey-medium;
$color-text-hint: $color-grey-strong;

// transparent colors
$color-transparent-primary: rgba(0, 0, 0, 0.5);

// background colors
$color-background-primary: $color-pink-light;
$color-background-hover-primary: $color-pink-strong;
$color-background-tertiary: $color-black-light;
$color-background-disabled: $color-grey;
$color-background-default: $color-white-strong;
$color-background-edit: $color-orange-strong;
$color-background-edit: $color-orange-strong;
$color-background-toned: $color-grey-medium;
$color-background-skeleton: $color-grey-light;


//// border colors
$color-border-primary: $color-pink-light;
$color-border-secondary: $color-grey-medium;
$color-border-input: $color-grey-light; //new
$color-border-input-focus: $color-pink-light; //new
$color-border-input-error: $color-red-light; //new

// over layer colors
$color-transparent-overlay-primary: rgba(255, 255, 255, 0.9);
$color-transparent-overlay-secondary: rgba(255, 255, 255, 0.4);

//
$color-error-primary: $color-red-light; //new
$color-icon-loader-primary: $color-grey-light;

