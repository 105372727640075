@mixin mixFlexAlignJustify($align, $justify, $direction: row) {
  display: flex;
  align-items: $align;
  justify-content: $justify;

  @if $direction {
    flex-direction: $direction;
  }
}

@mixin mixFlexColumn() {
  display: flex;
  flex-direction: column;
}

@mixin mixFlexColumnGap($gap) {
  @include mixFlexColumn();
  gap: $gap;
}
