.pool-page{

  //.emission-energy-box{
  //  margin-top: 4rem;
  //}
  //.project-cards-box{
  //  margin-top: 3.5rem;
  //}

  .discord{
    margin-top: 15rem;
  }
}
