.app-modal {
  @include mixOverLayerCentered(980);

  background-color: $color-transparent-primary;
}

.modal {
  @include mixFlexAlignJustify(center, space-between, column);

  //gap: 2.5rem;
  padding: 2rem 2.5rem;
  margin: auto 1rem;
  min-width: 25rem;
  box-shadow: $box-shadow-primary;
  background: $color-background-default;
  border-radius: 1rem;
  position: relative;
  text-align: center;

  .modal-description {
    @include mixFlexAlignJustify(center, center, column);
    gap: 0.5rem;
    color: $color-text-hint;
    font-size: 0.75rem;
    margin-top: 0.25rem;

    h6 {
      font-size: 0.875rem;
    }
  }

  //.modal-content {
  //  @include mixFlexAlignJustify(center, space-evenly, column);
  //  gap: 0;
  //  width: 100%;
  //  flex: 1;
  //
  //  .total-balance {
  //    font-family: $font-primary-semibold;
  //  }
  //}

  .space-element {
    flex: 1;
  }

  .modal-header {
    @include mixFlexAlignJustify(center, space-between);
    width: 100%;
    position: absolute;
    top: 0;
    background-position: center;
    padding: 1.75rem;

    button {
      height: 0.875rem;
      padding: 0;
    }

    img {
      object-fit: cover;
    }
  }

  h3 {
    color: $color-text-secondary;
    line-height: 3rem;
  }

  .action-buttons {
    display: flex;
    gap: 0.5rem;

    button {
      font-size: 0.875rem;
      padding: 0 1.5rem;
    }
  }

  .hint-message {
    font-size: 0.75rem;
    font-family: $font-primary-regular;
    color: $color-text-hint;
    line-height: 1.25rem;
  }

  .success {
    color: #09BC06
  }

  .fail {
    color: #E30000;
  }

  .status {
    margin-top: 2rem;
    line-height: 1.75rem;
  }

  .value {
    @include mixFlexAlignJustify(baseline, center);

    gap: 0.25rem;

    span {
      font-size: 1.5rem;
      font-family: $font-primary-medium;
    }
  }

  //.label {
  //  font-size: 1.25rem;
  //  margin-top: 0.5rem;
  //
  //}

  .description {
    margin-top: 1.5rem;
    font-size: 0.75rem;
    max-width: 18rem;
    line-height: 1.5rem;
    margin-bottom: 0.25rem;
    color: $color-text-hint;
  }

  // Deposit modal ==================================================================================================

  .deposit-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;

    .modal-input {
      position: relative;
      width: 60%;

      input {
        width: 100%;
        padding-right: 4rem;
        padding-left: 1.75rem;
        padding-top: 0.25rem;
      }

      // hide number input arrows
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }

      .currency-symbol {
        height: 3rem;
        left: 1rem;
        position: absolute;
        line-height: 3.25rem;
        color: $color-text-default;
        font-family: $font-primary-medium;
        font-size: 1.25rem;
      }

      .currency {
        height: 3rem;
        right: 1rem;
        position: absolute;
        line-height: 3.25rem;
      }

      //.error-message {
      //  margin-top: 0.5rem;
      //  font-size: 0.75rem;
      //  text-align: left;
      //  color: $color-error-primary;
      //}
    }

    .withdraw-checkbox {
      margin-top: 1rem;
      display: flex;
      gap: 0.5rem;
      align-items: center;

      &.disabled {
        color: $color-text-disabled;
      }

      input {
        margin: 0;
        accent-color: $color-background-primary;
      }

      h6 {
        margin-top: 0.2rem;
      }
    }

    .balance {
      @include mixFlexAlignJustify(center, space-between, column);

      gap: 0.5rem;
      font-size: 0.875rem;
      max-width: 80%;
      text-align: center;
      line-height: 1.5rem;

      .value {
        span {
          font-size: 1.25rem;
          font-family: $font-primary-medium;
        }
      }
    }

    .input-label {
      margin: 2.5rem 0 1.5rem 0
    }

    .edit-permission {
      color: $color-text-tertiary;
      font-family: $font-primary-regular;
      font-size: 0.875rem;
      text-decoration: underline;
    }

    .permission-value {
      margin: 2.5rem 0 0.25rem 0;
      text-align: center;

      .label {
        font-size: 1.25rem;
      }

      .value {
        margin-top: 0.75rem;

        span {
          font-size: 1.5rem;
          font-family: $font-primary-medium;
          margin-right: 0.25rem;
        }
      }
    }
  }

  .contract {
    @include mixFlexAlignJustify(center, center);
    gap: 0.5rem;

    position: relative;
    margin: 1.125rem 0 1.5rem 0;

    .pool-name {
      @include mixFlexAlignJustify(center, center);
      gap: 1rem;
      padding: 0.5rem 0.75rem;
      border-radius: 2.5rem;

      h6 {
        font-family: $font-primary-medium;
        height: 0.75rem;
      }

      h4 {
        font-size: 1.125rem;
      }

      img {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    a {
      width: 1.25rem;
      height: 1.25rem;
    }

    .address {
      display: flex;
      height: 2.25rem;
      line-height: 2.325rem;
      padding: 0 1rem;
      position: relative;
      border: mixItemBorder($color-border-input);
      border-radius: $border-radius-default;
      margin-right: 0.5rem;
      font-size: 0.875rem;
      font-family: $font-primary-medium;
    }

    button {
      padding: 0;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.copy-popup {
  position: absolute;
  padding: 0.25rem;
  border: mixItemBorder($color-border-input);
  background-color: $color-background-toned;
  border-radius: 0.5rem;
  top: -1.25rem;
  right: 1rem;
  font-family: $font-primary-medium;
  font-size: 0.75rem;
}

// Metamask modal ==========================================================================================================

.metamask {
  display: flex;
  gap: 3rem;
  padding-bottom: 2.5rem;

  .metamask-content {
    @include mixFlexAlignJustify(center, center);

    padding: 1.5rem 2rem;
    margin-top: 1rem;
    border: mixItemBorder($color-border-input);
    border-radius: 0.5rem;

    .title {
      padding: 0 1rem;
      color: $color-text-default;
    }
  }

  .action-buttons {
    margin: 0;
  }
}

//Inform modal ========================================================================================
.inform-modal {
  gap: 1.5rem;

  .inform-content {
    @include mixFlexAlignJustify(flex-start, center, column);
    text-align: left;
    gap: 0.5rem;

    :not(.title) {
      font-size: 0.875rem;
    }

    .title {
      font-family: $font-primary-medium;
      //font-size: 0.75rem;
    }
  }
}

//Menu modal ==========================================================================================
.modal-menu {
  height: 100%;
  width: 100%;
  margin: 0;
  justify-content: center;
  border-radius: 0;

  background: linear-gradient(338.62deg, #3B0C57 22.62%, #3C38F2 89.13%);
  box-shadow: 0 4px 24px -1px rgba(37, 22, 81, 0.16);
  backdrop-filter: blur(20px);
}

.menu-links-block {
  @include mixFlexAlignJustify(center, center, column);
  gap: 4rem;

  a {
    font-family: $font-primary-medium;
    color: $color-text-primary;
  }

  button {
    border: 0;
    background-color: $color-background-default;

    &:hover {
      background-color: $color-background-default;
      color: $color-text-secondary;
    }
  }
}

// global ==============================================================================================

.modal-large {
  width: 760px;
  min-height: 500px;
}

.modal-medium {
  width: 40rem;
}

.modal-small {
  width: 25rem;
}

.input {
  width: 100%;
  border-radius: 0.75rem;
  border: mixItemBorder($color-border-input);
  padding: 0.75rem 1rem;
  color: $color-text-default;
  font-family: $font-primary-medium;
  font-size: 1rem;
  line-height: 1.75rem;


  &:focus {
    border: mixItemBorder($color-border-input-focus);
  }

  &.error {
    border-color: $color-border-input-error;
  }
}

.modal-input {
  width: 100%;
  position: relative;

  .input-label {
    margin-bottom: 1rem;
  }

  .error-message {
    position: absolute;
    margin-top: 0.5rem;
    font-size: 0.75rem;
    text-align: left;
    color: $color-error-primary;
  }
}

.modal-input-box {
  @include mixFlexAlignJustify(center, center);
  gap: 1rem;
  width: 100%;
}

// contents =======================================================================
.withdraw-main {
  gap: 3.75rem;
}

.withdraw-yield {
  @include mixFlexAlignJustify(center, space-evenly, column);
  gap: 0;
  width: 100%;
  flex: 1;
  max-width: 32rem;

  .hint-message {
    margin-top: 0.5rem;
    text-align: start;
  }

  .yield-input-container {
    @include mixFlexAlignJustify(center, center, column);
    gap: 1rem;
    width: 100%;
  }

  .withdraw-investment {
    color: #0C4AE7;
    font-family: $font-primary-medium;
    font-size: 0.75rem;
    line-height: 1.25rem;

    p {
      text-decoration: underline;
    }
  }

  //.yield-input-container {
  //  width: 100%;
  //  height: 3.25rem;
  //  padding: 0.375rem;
  //  border: 1px #C8C8C8 solid;
  //  border-radius: 0.75rem;
  //}

  .yield-input-value {
    @include mixFlexAlignJustify(center, center);
    gap: 0.5rem;
    height: 100%;
    width: fit-content;
    background: #F1F1F1;
    padding: 0.375rem 0.75rem;
    border-radius: 0.5rem;
    color: #999494;
  }
}

.reinvest-yield {
  @include mixFlexAlignJustify(center, space-between, column);
  width: 100%;
  gap: 0;
  flex: 1;

  .reinvest-sub-title {
    @include mixFlexAlignJustify(center, center);
    gap: 0.375rem;
    margin-top: 0.25rem;

    h6 {
      font-size: 0.875rem;
    }
  }
}
