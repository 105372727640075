.spinner {
  background: $color-background-default;
  box-shadow: 1px 1px 24px rgba(10, 12, 18, 0.08);
  border-radius: 2rem;
  font-family: $font-primary-medium;
  font-size: 0.825rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  color: $color-text-tertiary;
  letter-spacing: 0.04em;
}

.spinner-block {
  flex: 1;
  display: flex;
  gap: 0.5rem;
  justify-content: end
}

.loader {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

