.story-page {

  .page-content {
    @include mixFlexAlignJustify(center, center, column);
    gap: 3.75rem;
    margin-top: 1rem;

    .active {
      height: 32.5rem;
      width: 100%;
      pointer-events: none;
      .story-card{
        height: 100%;
      }
    }
  }

  .sub-content {
    @include mixFlexAlignJustify(center, center, column);
    gap: 3.75rem;
  }

  .stories {
    @include mixFlexAlignJustify(center, center, column);
    width: 100vw;
    position: relative;

    .title-description {
      margin-bottom: 3rem;
    }

    .gradient {
      height: 1400px;
      top: -700px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  .article {
    h4{
      text-transform: uppercase;
    }
    p {
      margin-top: 1rem;
      line-height: 1.75rem; // 28px

      @include mixBreakPointSM {
        text-align: justify;
      }
    }
  }

  .discord {
    margin-top: 15rem;
  }
}
