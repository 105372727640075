.page-content-container {
  @include mixFlexAlignJustify(center, center, column);
  position: relative;
  padding: 0 1.5rem;
  width: 100%;
}

.page-content {
  @include mixFlexAlignJustify(center, start, column);
  max-width: 75%; // ?px
  width: 100%;

  @include mixBreakPointXL {
    max-width: 75%; // ?px
  }

  @include mixBreakPointL {
    max-width: 70rem; // 1080px
  }

}

.app-content-container {
  @include mixFlexAlignJustify(center, center, column);
  width: 100%;
  height: 100%;

  .pool-image {
    position: relative;
    height: 25rem;
    width: 100%;
    top: 0;
    overflow: hidden;
    z-index: -1;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(10, 12, 18, 0) 0%, rgba(10, 12, 18, 0.9) 150%)
    }

    .closed-badge {
      @include mixFlexAlignJustify(center, center, column);

      position: absolute;
      top: 1.25rem;
      right: 1.25rem;
      height: 1.5rem;
      width: 5.125rem;
      text-align: center;
      color: $color-text-primary;
      background-color: $color-background-primary;
      font-family: $font-primary-medium;
      font-size: 0.75rem;
      border-radius: 3rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .skeleton {
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
    }
  }


  .app-content {
    @include mixFlexAlignJustify(start, center);
    margin-top: -16rem;
    gap: 5rem;
    width: 100%;
    padding: 0 2rem;
    max-width: 76.5rem; // ?px
    z-index: 25;

    @include mixBreakPointLG {
      gap: 3rem;
    }

    @include mixBreakPointMD {
      @include mixFlexAlignJustify(center, center, column-reverse);
      gap: 3rem;
    }

    .left-section {
      flex: 2;
      max-width: 740px;
      @include mixFlexAlignJustify(flex-start, center, column);
      gap: 2rem;

      @include mixBreakPointLG {
        flex: 3;
      }

      @include mixBreakPointMD {
       align-items: center;
      }
    }

    .right-section {
      @include mixFlexAlignJustify(center, center, column);
      flex: 1;
      top: 9rem;
      position: sticky;

      @include mixBreakPointLG {
        flex: 2;
      }

      @include mixBreakPointMD {
        flex: auto;
        top: auto;
        position: inherit;
      }
    }
  }
}


.sub-content {
  @include mixFlexAlignJustify(center, center, column);
  max-width: 70rem; // 1080px

  @include mixBreakPointLG {
    max-width: 56rem; // 896px
  }
}


