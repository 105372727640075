@function mixGetBorder($width,$color) {
  $result: $width solid $color;
  @return $result;
}

@function mixItemBorder($color) {
  @return mixGetBorder(1px, $color);
}

@mixin mixCardBorder() {
  position: relative;
  box-shadow: $box-shadow-primary;

  //--border-width: 2px;
  --border-radius: 1rem;
  --color-1: rgba(255, 255, 255, 0.6); //opacity was 0.4 initially
  --color-2: rgba(255, 255, 255, 0);

  background: linear-gradient(137.69deg, var(--color-1), var(--color-2));
  background-repeat: no-repeat;
  border-radius: var(--border-radius);
  border: none;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 0.875rem;
    top: 0;
    bottom: 0;
  }

}
