.pools-page, .helios-app-page {
  .page-title {
    padding: 7.25rem 2rem 0 2rem;
    max-width: 75%; // ?px
    width: 100%;

    @include mixBreakPointXL {
      max-width: 75%; // ?px
    }

    @include mixBreakPointL {
      max-width: 70rem; // 1080px
    }
  }

  .account-impact-info {
    @include mixFlexAlignJustify(center, center, column);
    gap: 3.5rem;
  }

  .available-pools {
    @include mixFlexAlignJustify(center, center, column);

    max-width: 70rem; // ?px
    width: 100%;

    @include mixBreakPointXL {
      max-width: 75rem; // ?px

      //max-width: 75%; // ?px
    }

    @include mixBreakPointL {
      max-width: 70rem; // 1080px
    }

    padding: 2rem;
    gap: 2.5rem;
    margin-top: 6rem;


    @include mixBreakPointSM {
      padding: 1rem;
    }

    .cards-slide {
      padding: 0
    }

    .region-filter {
      display: flex;
      gap: 4.75rem;
      max-width: 100%;
      overflow-y: hidden;
      overflow-x: scroll;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      @include mixBreakPointMD {
        gap: 4rem;
      }

      @include mixBreakPointSM {
        gap: 2rem;
      }

      button {
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 0.3em;
        padding: 0;

        &.active-region {
          color: $color-text-secondary;
        }
      }
    }

    .gradient {
      min-width: 100vw;
      position: absolute;
      height: 1500px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

  }

  .closed-pools {
    @include mixFlexAlignJustify(center, center, column);

    max-width: 75%; // ?px
    width: 100%;

    @include mixBreakPointXL {
      max-width: 75%; // ?px
    }

    @include mixBreakPointL {
      max-width: 70rem; // 1080px
    }

    gap: 3.75rem;
    margin-top: 5rem;
    margin-bottom: 6.25rem;

    .gradient {
      min-width: 100vw;
      position: absolute;
      height: 1700px;
      top: 500px;
      left: auto;
    }
  }


}
