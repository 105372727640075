.helios-app-page {

  .investments {
    @include mixFlexAlignJustify(center, center, column);
    gap: 4.25rem;

    text-align: center;
    width: 100%;
    z-index: 25;
    margin-top: 11rem;
    max-width: 70rem;
    padding: 2rem;

    .empty-investments {
      @include mixFlexAlignJustify(center, center, column);
      color: #B8B7C6;
      font-size: 1.5rem;
      height: 450px;
      width: 100%;
      border-radius: 1rem;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), linear-gradient(135deg, #F2EDFB 0%, #E4EAF7 100%);
      box-sizing: border-box;
    }
  }

  .charts {
    @include mixFlexAlignJustify(center, center, column);
    gap: 3rem;
    padding: 9.25rem 2rem 0 2rem;
    max-width: 70rem;
    width: 100%;
    z-index: 25;

    h2, h3 {
      line-height: 3rem;
    }
  }

  .account-impact-info {
    margin: 17rem 1rem 7rem;

    .gradient {
      min-width: 100vw;
      height: 2000px;
      top: 700px;
      position: absolute;
      left: auto;
      z-index: -1;
    }
  }

  .available-pools {
    margin-bottom: 15rem;
  }
}
