.home-page {
  overflow: hidden;

  .page-title {
    margin-top: 7.25rem;

    @include mixBreakPointMD {
      margin-top: 2rem;
    }

    @include mixBreakPointSM {
      margin-top: 0;
    }
  }

  .text-image-park {
    margin-top: 17.125rem;
  }

  .impact-info {
    margin-top: 12rem;
    gap: 4rem;
    text-align: center;
  }

  .work-flow {
    position: relative;
    margin-top: 13rem;
    gap: 5rem;

    @include mixBreakPointSM {
      //margin-top: 11.5rem;
      gap: 3.25rem; //52px
    }

    .gradient {
      min-width: 100vw;
      height: 1800px;
      position: absolute;
      left: auto;
      z-index: -1;
    }
  }

  .success-stories {
    @include mixFlexAlignJustify(center, center, column);

    margin-top: 14rem;
    width: 100%;
    overflow: hidden;
    gap: 4rem;

    @include mixBreakPointMD {
      width: 100vw;
      //margin-top: 16.5rem;
    }

    @include mixBreakPointSM {
      gap: 3.25rem;
      //margin-top: 15rem;
    }
  }

  .companies {
    @include mixFlexAlignJustify(center, center, column);

    margin-top: 14rem;
    width: 100%;
    gap: 2rem;

    @include mixBreakPointSM {
      //margin-top: 15rem;
      gap: 1.5rem;
    }
  }

  .testimonials {
    @include mixFlexAlignJustify(center, center, column);
    position: relative;
    width: 100vw;
    margin-top: 12rem;
    //gap: 2.5rem;

    //@include mixBreakPointSM {
    //  margin-top: 12rem;
    //  //gap: 3.25rem;
    //}

    .gradient {
      max-height: 1400px;
      position: absolute;
      left: auto;
      z-index: -1;
    }

    a {
      font-family: $font-primary-medium;
      text-decoration: underline;
      color: $color-text-default;
    }
  }

  .our-mission {
    margin-top: 10rem;

    @include mixBreakPointSM {
      //margin-top: 15rem;
    }

    .description {
      b {
        font-family: $font-primary-bold;
      }
    }
  }

  .our-team {
    @include mixFlexAlignJustify(center, center, column);

    width: 100%;
    margin-top: 25.625rem;
    gap: 2.5rem;
  }

  .community {
    @include mixFlexAlignJustify(center, center, column);
    max-width: 60rem;
    gap: 2rem;
    margin-top: 14rem;
    margin-bottom: 14rem;
    text-align: center;
    position: relative;

    //@include mixBreakPointMD {
    //  margin-top: 25rem;
    //  margin-bottom: 22.25rem;
    //}
    //
    //@include mixBreakPointSM {
    //  margin-top: 17.25rem;
    //  margin-bottom: 22.25rem;
    //}

    h1{
      font-size: 2.75rem;
      font-family: $font-primary-bold;
      background: linear-gradient(112.26deg, #EC17D7 8.73%, #344FDA 84.89%), #FFFFFF;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      line-height: 4.125rem;

      @include  mixBreakPointMD {
        line-height: 3rem;
        font-size: 2.25rem;
        max-width: 41rem;
      }

      @include  mixBreakPointSM {
        line-height: 2.5rem;
        font-size: 1.625rem;
      }
    }

    .gradient {
      max-height: 1350px;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: -1;
    }

    .ceo {
      font-size: 1.25rem;
      font-family: $font-primary-medium;
      letter-spacing: 0.4px;

      @include  mixBreakPointSM {
        line-height: 2rem;
        font-size: 1rem;
      }
    }
  }
}
